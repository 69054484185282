import React,{useEffect} from 'react'
import Style from './successfull.module.scss'
import Logo from '../../../../assets/theme6/quizwall.png'
import { useNavigate,useLocation } from 'react-router-dom'
import SuccesssAnimation from '../Animation/successAnimation'
import SuccessImg from '../../../../assets/theme6/failed.svg'



const Successfull = () => {
  const search = useLocation().search;
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // Optional: smooth scrolling animation
});

  const navigate = useNavigate()
  const reward = new URLSearchParams(search).get('reward');

  // console.log(window.location.href)

  sessionStorage.setItem("lastScreen", window.location.href);


  

  


  useEffect(() => {
        
    window.location.hash = window.location.hash+"no-back-button";
    window.location.hash = "Again-No-back-button";
    window.onhashchange = function () {
    window.location.hash = "no-back-button";
    
}

}, [])
  return (
    <>
      <div className={Style.container}>
        <div className={Style.logo}>
          <img src={Logo} width={"130px"} alt="logo" />
        </div>
        <div style={{position:"absolute",top:"50px"}}>
          <SuccesssAnimation />
        </div>
        <div className={Style.reward}>


          <img src={SuccessImg} alt="" />

          {/* <p className={Style.rewardLine}>{reward} Coins has been credited to your account</p> */}

        </div>

        <div style={{display:"flex",alignItems:"center",justifyContent:"center",marginTop:"100px"}}>
          <button className={Style.btn} onClick={() => { navigate('/home') }}> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
            <path d="M14 5.83832L5.17647 1.60303M12.2353 10.7795L2 10.7795M14 15.3677L8 18.8971" stroke="white" stroke-width="2.11765" stroke-linecap="round" />
          </svg><p>Play more</p> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
              <path d="M2 5.83832L10.8235 1.60303M3.76471 10.7795L14 10.7795M2 15.3677L8 18.8971" stroke="white" stroke-width="2.11765" stroke-linecap="round" />
            </svg></button>
        </div>
      </div>
    </>
  )
}

export default Successfull