import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import TrendingT from '../../../assets/img/trending.png'
import { getMissionAction } from '../../../redux/screen/mission';
import { useNavigate } from 'react-router-dom'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import LimitPopup from '../popup/LimitPopup';



function Trending() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [limit,setLimit] = useState(10);
    const [page, setPage] = useState(0)
    const getMissionSelector = useSelector(state => state.getMission)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [isMore, setIsMore] = useState(false)
    const { length} = getMissionSelector
    const getAppSelector = useSelector(state => state.getApp)
    const {data:{is_ads_enabled},Config:{daily_user_attempt_limit}}=getAppSelector
    const userDetialsSelector = useSelector(state => state.getUserDetails)
    const {data:userData}=userDetialsSelector
    const { data:{daily_attempt_limit_count} } = userDetialsSelector
    const [showPopup, setShowPopup] = useState(false);
    const [open, setOpen] = React.useState(false);

   



    // console.log("userData-->",userData);



//   useEffect(()=>{
// console.log("page-->",page);
//   },[page])

const fetchData = async () => {
    try {
        setIsMore(false)
        setLoading(true)
        setOpen(true)
        let res = await dispatch(getMissionAction({ page: page, limit: limit }));
        if (res?.payload?.status === 200) {
            setData(prevData => [...prevData, ...res?.payload?.data]);
            // if(res?.payload?.length<limit){

            // }
        setPage(prev=>prev+1)

            if (res?.payload?.data.length >0) {
                if(res?.payload?.data.length <10){
                    setIsMore(false)
                }else{
                    setIsMore(true)

                }
            }else{

                setIsMore(false)
            }
            setLoading(false)
        setOpen(false)

        }

    } catch (error) {
        console.log("Error:", error);
    }
};

    useEffect(() => {
       

        fetchData();

    }, [dispatch])

    const clickHandler=(item)=>{
        if(daily_attempt_limit_count>=daily_user_attempt_limit){
            setShowPopup(true)
        }else{
            navigate('/mission-detail', { state: { mission_id: item._id } }) 

        }
    }

    // const viewMore = async () => {
    //     setIsMore(true)
    //     setLoading(true)
    //     setOpen(true)
    //     setPage(prev=>prev + 1)
    //     let response = await dispatch(getMissionAction({ page: page, limit: 10 }))
    //     if (response?.payload?.status === 200) {
    //         // setData([...response?.payload?.data]);
    //         setData(prevData => [...prevData, ...response?.payload?.data]);
    //         if(response?.payload?.data.length>data.length){
    //             setIsMore(false)
    //         }
    //         setLoading(false)
    //         setOpen(false)
    //     }



    // }

    // const viewAll=async()=>{
    //     setLoading(true)
    // setPage(page+1)
    // let response=await dispatch(getMissionAction({page:page,limit:10000}))
    // console.log("response?",response)
    // if(response?.payload?.status===200){
    //     setData(prevData => [...prevData, ...response?.payload?.data]); 
    //     if(response?.payload?.length<limit){
    //         setIsMore(true)
    //     }
    //     console.log("length-->",data.length)
    //     setLoading(false)
    //  }





    return (
        <>
            <div className="banner">

                {/* <img className={styles.banner_img_top} src={TrendingT} /> */}
                <img src={TrendingT} style={{ zIndex: 3 }} />

            </div>
            <div className="trending__heading">
              <p>Trending Quizzes</p>
            </div>
            <div className="mission_sec">
                
                {loading ? <Box sx={{ width: "95%" }}>
      
                <Skeleton style={{borderRadius:"10px"}} animation="wave" variant="rectangular" width={"100%"} height={130} />
                <br />
                <Skeleton style={{borderRadius:"10px"}} animation="wave" variant="rectangular" width={"100%"} height={130} />
                <br />
                <Skeleton style={{borderRadius:"10px"}} animation="wave" variant="rectangular" width={"100%"} height={130} />
                <br />
                <Skeleton style={{borderRadius:"10px"}} animation="wave" variant="rectangular" width={"100%"} height={130} />
               
      
    </Box> : data?.length > 0 ? (data.map((item, index) => {
    
                    return (
                        // <div key={index}>
                        <>
                            <div className="continer" key={item?._id} >
                                <div className="container1" key={item?._id}>
                                    <div className="image_div">
                                        <img src={item?.icon} alt="icon" />
                                    </div>
                                    <div className="item_container">
                                        <div className="Cash_bonanza_div">{item?.title}</div>
                                        <div className="Fast_food_div">{item?.sub_category}</div>
                                        <div className="fast_food_detail_container">
                                            <div className="food_detils" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><SmartDisplayOutlinedIcon style={{ color: "#b6b6b6", fontSize: "16px" }} /></div>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;&nbsp;{item?.no_of_question}</div>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;Q</div>
                                            </div>
                                            |
                                            <div className="food_detils" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><HourglassEmptyIcon style={{ color: "#b6b6b6", fontSize: "16px" }} /></div>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;&nbsp;{item?.time_criteria}</div>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;mins</div>
                                            </div>
                                            |
                                            <div className="food_detils" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><PersonOutlineOutlinedIcon style={{ color: "#b6b6b6", fontSize: "18px" }} /></div>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;&nbsp;{item.participants}</div>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button onClick={()=>clickHandler(item)} className="button_9" role="button">
                                    Play Now
                                </button>

                            </div>

                            {index !== item.length - 1 && is_ads_enabled && <div className="continer">
                                ads
                            </div>}

                        </>

                        // </div>
                    )
                })) : (<p style={{ marginTop: "20px", wordWrap: 'break-word',textAlign:"center" }}> Mission not available right now,<br/> Please try again later.</p>)}

                {isMore  &&
                    <p className='view_more' onClick={()=>fetchData()}>View More</p>
                }

      {showPopup && <LimitPopup open={showPopup} setOpen={setShowPopup}/>}

            </div>

        </>
    )
}

export default Trending