import { initializeApp } from "firebase/app";



const firebaseConfig = {
    apiKey: "AIzaSyAO5x8z5SmXVcOLBm6_NhNdQYMb7AOwLtY",
    authDomain: "quizwall-io-e091a.firebaseapp.com",
    projectId: "quizwall-io-e091a",
    storageBucket: "quizwall-io-e091a.appspot.com",
    messagingSenderId: "642684571918",
    appId: "1:642684571918:web:e87df47f53fb748ba8075f",
    measurementId: "G-2BBSEB5WBS"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);



