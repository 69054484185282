import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import Appbar from '../component/Appbar'
import { getMissionActionBySubCat } from '../../../../redux/screen/missionbysubcategory'
import LoadingUI from '../../../../comman/LoadingUI'
import LimitPopup from '../component/LimitPopup'
import LimitPopup2 from '../component/LimitPopup2'
import Style from '../style/trending.module.scss'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Play_btn from '../../../../assets/theme6/btn_image.svg'
import Fav from '../../../../assets/theme6/fav1.svg'
import Unfav from '../../../../assets/theme6/unfav.svg'
import { favoriteAction } from '../../../../redux/profile/favorite';
import { unfavoriteAction } from '../../../../redux/profile/unfavorite';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { getUserDetailsAction } from '../../../../redux/user/getUserDetails';
import { getMissionDetailAction } from '../../../../redux/screen/missiondetails';





// import { getMissionAction } from '../../../redux/screen/mission'


// import './mission.css'


export default function Mission() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const sub_cat_id = location.state.sub_cat_id
    const subcatname = location.state.subcatname
    const getMissionSelector = useSelector(state => state.getMissionBySubCat)
    const { data: missionData, loading, status, message } = getMissionSelector
    const getAppSelector = useSelector(state => state.getApp)
    const { data: { is_ads_enabled }, Config: { daily_user_attempt_limit } } = getAppSelector
    const userDetialsSelector = useSelector(state => state.getUserDetails)
    const { data: { daily_attempt_limit_count } } = userDetialsSelector
    const [showPopup, setShowPopup] = useState(false);
    const [isFav, setIsFav] = React.useState([])
    const [userDeviceWidth,setUserDeviceWidth]=useState()
    const [open1, setOpen1] = React.useState(false);

    const currentUrl = window.location.href;



    useEffect(() => {
      
        // fetchUserDetails()
        setUserDeviceWidth(window.innerWidth)
    
     
    }, [])

    // const fetchUserDetails = async () => {

    //     const res = await dispatch(getUserDetailsAction())
    //     // console.log("res-->",res.payload.data.favorites)
    //     const data = res?.payload?.data?.favorites
    //     setIsFav([...data])


    // }
    


    // window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth' // Optional: smooth scrolling animation
    // });


    if (status === 401) {
        alert(message)
    }

    useEffect(() => {
        dispatch(getMissionActionBySubCat(sub_cat_id))
        // dispatch(getMissionAction())
    }, [])

    const clickHandler = (item) => {
        if (daily_attempt_limit_count >= daily_user_attempt_limit) {
            setShowPopup(true)
        } else {

            dispatch(getMissionDetailAction(item._id))
  .then((res) => {

    // console.log(res,"res")
    if(res.payload.status!==403)
            navigate('/mission-detail', { state: { mission_id: item._id } })
     else
      setOpen1(true)
    
    
    
   
  })
  .catch((error) => {
    // Handle any errors that occur during the dispatch
    console.error('Error dispatching mission detail action:', error);
  });

            

        }
    }

    const favHandler = (value) => {
        // console.log("value", value)

        if (isFav.includes(value)) {
            setIsFav([...isFav.filter(item => item !== value)])
            dispatch(unfavoriteAction({ mission_id: value }))

        } else {
            setIsFav([...isFav, value])
            dispatch(favoriteAction({ mission_id: value }))
        }

    }



    return (
        <>
            <Appbar />

            <div className={Style.main_div}>

                <div className={Style.banner_sub_heading}>
                    <p>{subcatname}</p>
                </div>
                <div className={Style.starting_text}>
                    <p>You’re just a click away to start your quiz</p>
                </div>
                <div className={Style.mission_sec}>
                    {loading ? (
                        <>

                        <Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"90%"} height={130} />
                        <br />
                        <Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"90%"} height={130} />
                        <br />
                        <Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"90%"} height={130} />
                        <br />
                        <Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"90%"} height={130} />
                        
                        
                        </>
                    ) : (
                        missionData?.length > 0 ? (
                            missionData.map((item, index) => (
                                <div className={Style.continer} key={item?._id}>
                                    {/* <div key={"fav" + item?._id} onClick={() => { favHandler(item?._id) }} style={{ position: "absolute", top: "0px", right: "0px" }}>
                                        {isFav.includes(item?._id) ? <img src={Fav} alt="fav" /> : <img src={Unfav} alt="fav2" />}
                                    </div> */}
                                    <div className={Style.container1}>
                                        <div className={Style.image_div}>
                                            <img src={item?.icon} alt="icon" />
                                        </div>
                                        <div className={Style.item_container}>
                                            <div className={Style.Cash_bonanza_div}>{(userDeviceWidth<450 && item?.title.length>14) ? item?.title.substring(0,14)+'...':item?.title.substring(0,18)}</div>
                                            <div className={Style.Fast_food_div}>{subcatname}</div>
                                            <div className={Style.fast_food_detail_container}>
                                                <div className={Style.food_detils} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><SmartDisplayOutlinedIcon style={{ color: "#b6b6b6", fontSize: "16px" }} /></div>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;&nbsp;{item?.no_of_question}</div>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;Q</div>
                                                </div>
                                                |
                                                <div className={Style.food_detils} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><HourglassEmptyIcon style={{ color: "#b6b6b6", fontSize: "16px" }} /></div>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;&nbsp;{item?.time_criteria}</div>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;mins</div>
                                                </div>
                                                |
                                                <div className={Style.food_detils} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><PersonOutlineOutlinedIcon style={{ color: "#b6b6b6", fontSize: "18px" }} /></div>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;&nbsp;{item.participants}</div>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button onClick={() => clickHandler(item)} className={Style.button_9} role="button">
                                        Play Now
                                        <img className={Style.btn_img} src={Play_btn} width={"60px"} height={"60px"} />
                                    </button>

                                    {index !== missionData.length - 1 && is_ads_enabled && <div className={Style.continer}>ads</div>}
                                </div>
                            ))
                        ) : (
                            <div style={{ marginTop: "20px", wordWrap: 'break-word', textAlign: "center",display:"flex",alignItems:"center",justifyContent:"center" ,color:"#fff",width:"100%",height:"50vh"}}>
                            <p> Mission not available right now,<br /> Please try again later.</p>
                            </div>
                        )
                    )}





                </div>
            </div>
            {showPopup && <LimitPopup open={showPopup} setOpen={setShowPopup} />}
            {open1 &&(<LimitPopup2 open={open1} setOpen={setOpen1} currentUrl={currentUrl} />)}





        </>
    )
}
