import React, { useEffect } from 'react'
import Styles from './style/home.module.scss'
import Categories from './component/Categories'
import Trending from './component/Trending'
import Topimg from '../../../assets/theme6/top.png'
import Profile from '../../../assets/theme6/profile_icon.svg'
import Fav from '../../../assets/theme6/fav.svg'
import { Link } from 'react-router-dom'
import { Typography } from '@mui/material';
import Heart from '../../../assets/theme6/heart.svg'
import RocketIcon from '@mui/icons-material/Rocket';
import MenuIcon from '@mui/icons-material/Menu';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import ArticleIcon from '@mui/icons-material/Article';
import ClearIcon from '@mui/icons-material/Clear';
import HistoryIcon from '@mui/icons-material/History';
import PersonPinIcon from '@mui/icons-material/PersonPin';






const Theme6 = () => {

  // const lastLocationPath = document.referrer;
  // console.log(lastLocationPath);

  const lastScreen = JSON.stringify(sessionStorage.getItem("lastScreen"))
  const [sidebar, setSidebar] = React.useState(false)



  useEffect(() => {

    if (lastScreen.includes("mission-success") || lastScreen.includes("mission-unsuccess")) {


      window.location.hash = "no-back-button";
      window.location.hash = "Again-No-back-button";
      window.onhashchange = function () {
        window.location.hash = "no-back-button";
      }
    }
  }, [])

  return (
    <>
      <div className={Styles.parent} style={{ background: "#fff" }}>
        <div className={Styles.menu}>

          <div className={Styles.profile__div} style={{position:'absolute',top:"20px",right:"40px"}}>

            
            <MenuIcon className="menu" onClick={() => { setSidebar(!sidebar) }} color='#fff' />
           

            <div className={Styles.sidbar_parent}>
             {sidebar && <div className={Styles.sidebar_left} onClick={() => { setSidebar(!sidebar) }}>

              </div>}
              {sidebar && <div className={Styles.sidebar}>
                <div className={Styles.head}>
                  <ClearIcon onClick={() => { setSidebar(!sidebar) }} color='action' style={{ color: "white", float: "right", margin: "20px 20px 0px 0px" }} />
                </div>
                <div className={Styles.body} style={{ clear: "both" }}>
                  
                  <h2>Menu</h2>
                  <hr style={{color:"#fff",position:"relative",top:"-20px",border:"2px solid #000"}}/>
                  <ul>

                  <Link style={{textDecoration:"none",color:"inherit"}} to={'/profile'}> <tr style={{marginTop:"10px"}}><li><PersonPinIcon fontSize='large'/></li><li><h3 style={{marginTop:"8px"}}>Profile</h3></li></tr></Link>
                  <Link style={{textDecoration:"none",color:"inherit"}} to={'/history'}> <tr style={{marginTop:"10px"}}><li><HistoryIcon fontSize='large'/></li><li><h3 style={{marginTop:"8px"}}>History</h3></li></tr></Link>
                    <a style={{ textDecoration: "none", color: "inherit"}} href='https://publisher.quizwall.io/privacy'> <tr style={{marginTop:"10px"}}><li><PrivacyTipIcon fontSize='large' /></li><li><h3 style={{ marginTop: "8px" }}>Privacy Policy</h3></li></tr></a>
                    <a style={{ textDecoration: "none", color: "inherit",}} href="https://publisher.quizwall.io/term-of-use"> <tr style={{marginTop:"10px"}}><li><ArticleIcon fontSize='large' /></li><li><h3 style={{ marginTop: "8px" }}>Terms and condition</h3></li></tr></a>




                  </ul>
                  
                </div>

              </div>}
            </div>



            {/* <p>Menu</p> */}
          </div>

          <div className={Styles.profile__div} style={{marginTop:"30px"}}>

            <Link to={'/profile'}>
              <img className={Styles.profile} width={"100%"} src={Profile} />
            <p>Profile</p>

            </Link>

          </div>
          <div className={Styles.profile__div}>
            <Link to={'/favorite'}>
              <img className={Styles.profile} width={"100%"} src={Fav} />
            <p>Favorites</p>

            </Link>

          </div>

        </div>
        <img width={"100%"} src={Topimg} />
        {/* {JSON.stringify(window.innerWidth + "screen width")}
        {JSON.stringify(window.innerHeight + "screen height")} */}
        <Categories />
        <Trending />

        <div className={Styles.bottom_text}>Pick, <br /> Play & <span className={Styles.color_text}>Earn</span></div>
        <div id="bottomDiv" className={Styles.jingle_text}>Made with <img style={{ position: 'relative', top: "5px" }} src={Heart} /> by QuizWall.IO</div>
        {/* <div className="rocket_color" style={{paddingBottom:"10px",borderRadius:"50%",fontSize:"16px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}><RocketIcon style={{fontSize:"48px"}} onClick={()=>{window.scrollTo(0,0);}}/><Typography sx={{fontFamily:"gantari"}}>Take me upp</Typography></div> */}
      </div>






    </>
  )
}

export default Theme6