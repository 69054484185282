import React,{useState} from 'react'
import Style from './AvatarModal.module.scss'
import Girl from '../../../../assets/theme6/girl.svg'
import Boy from '../../../../assets/theme6/boy.svg'
import Tick from '../../../../assets/theme6/tick.svg'
import BoyT from '../../../../assets/theme6/boy_tick.svg'
import GirlT from '../../../../assets/theme6/girl_tick.svg'
import authAxios from '../../../../config/authAxios'
import { getUserDetailsAction } from '../../../../redux/user/getUserDetails'
import { useDispatch } from 'react-redux'


const AvatarModal = ({isAvatarModal,setIsAvatarModal,avatar_type}) => {

    const dispatch=useDispatch()

    const closeHandler=()=>{
        setIsAvatarModal(false)
    }

    const [avatar,setAvatar]=useState(avatar_type);


    const avatarChangeHandler=async()=>{

        const data=await authAxios.put('/user/api/edit-profile',{avatar_type:avatar})
        dispatch(getUserDetailsAction())

        setIsAvatarModal(false)

    }

    return (
        <>
            <div id="myModal" className={Style.modal}>

                <div className={Style.modal__content}>
                    <div style={{position:"relative",zIndex:"10"}} onClick={()=>{closeHandler()}} className={Style.close}>&times;</div>
                    <div className={Style.parent}>
                        <div className={Style.text}>
                            <p className={Style.heading}>Choose your avatar</p>
                            <p className={Style.subHeading}>This avatar will be updated to you Quiz wall profile</p>
                        </div>
                        <div className={Style.images}>
                            <div className={Style.img1} onClick={()=>{setAvatar(1)}}>{avatar==1?<img src={BoyT} width={"119px"} alt="boy_profile" />:<img src={Boy} width={"119px"} alt="boy_profile" />}</div>
                            <div className={Style.img2} onClick={()=>{setAvatar(2)}}>{avatar==2?<img src={GirlT} width={"119px"} alt="boy_profile" />:<img src={Girl} width={"119px"} alt="boy_profile" />} </div>
                        </div>
                        <div className={Style.btn}>
                            <button onClick={avatarChangeHandler} className={Style.perfectBtn}><p><svg xmlns="http://www.w3.org/2000/svg" width="12" height="18" viewBox="0 0 12 18" fill="none">
  <path d="M10.9592 5.07233L3.57628 1.31006M9.48264 9.46165L0.918412 9.46165M10.9592 13.5374L5.93882 16.6727" stroke="white" stroke-width="1.77191" stroke-linecap="round"/>
</svg>Perfect<svg xmlns="http://www.w3.org/2000/svg" width="12" height="18" viewBox="0 0 12 18" fill="none">
  <path d="M0.877563 5.07233L8.26052 1.31006M2.35415 9.46165L10.9184 9.46165M0.877563 13.5374L5.89797 16.6727" stroke="white" stroke-width="1.77191" stroke-linecap="round"/>
</svg></p></button>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default AvatarModal