import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Appbar from '../../comman/Appbar'
import { useDispatch, useSelector } from 'react-redux'
import Points from '../../assets/icon/points.png'
import { getUserTransactionAction } from '../../redux/user/getUserTransaction'
import { getUserDetailsAction } from '../../redux/user/getUserDetails'
import moment from 'moment'
import NA from '../../assets/icon/na.png'
import DailyStreak from '../../assets/img/daily_streak.png'
import SpinIcon from '../../assets/img/spinwheel_small.png'

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';













export default function Profile() {
    // state
    window.scrollTo(0, 0);

    const dispatch = useDispatch()

    const getUserDetails = useSelector(state => state.getUserDetails)
    const { data: userData, status, message } = getUserDetails
    // console.log("userData-->", userData)

    const getUserTransaction = useSelector(state => state.getUserTransaction)
    const { data: TransactionData, loading } = getUserTransaction

    // console.log("TransactionData", TransactionData)




    // fn
    // const handleProfile = async (data) => {
    //     let profileDetails = await dispatch(addProfileDetailsAction(data))
    //     if (profileDetails?.payload?.status == 200) {
    //         setSnack(true)
    //         navigate("/survey")
    //     }
    // }


    useEffect(() => {
        dispatch(getUserTransactionAction({ page: 0, limit: 5 }))
        dispatch(getUserDetailsAction())
    }, [])





    return (
        <>
            <Appbar title='Profile' />
            <div className='bg_color' style={{ width: "100%", minHeight: "100vh", display: "flex", flexDirection: "column", alignItems: 'center', overflow: 'hidden' }}>
                <div className="profile__section">
                    <div className='round__profile__section'>
                        <div className='profile_div' style={{ boxSizing: "border-box", width: "50px", height: "50px" }}>
                            <p>{userData?.email?.charAt(0).toUpperCase()}</p>

                        </div>
                        <div className="profile__name">
                            {userData?.name ? userData?.name : "---"}
                        </div>
                    </div>
                </div>



                <div className="profile__detail__section">
                    <div className="email p_detail">
                        <div className="logo"><MailOutlineIcon /></div>
                        <div className="content">Email:&nbsp;{userData?.email ? userData?.email : "---"}</div>
                    </div>
                    {/* <div className="number p_detail">
                        <div className="logo"><CallOutlinedIcon /></div>
                        <div className="content">Registered Mobile:&nbsp;{userData?.mobile ? userData?.mobile : "---"}</div>
                    </div>
                    <div className="coins p_detail">
                        <div className="logo"><img src={Points} alt="coin" width="35px" height="35px" /></div>
                        <div className="content" style={{ marginLeft: "-10px" }}>Total Earnings:&nbsp;{userData?.reward ? userData?.reward : "---"}</div>
                    </div> */}
                </div>



                <div className="history__heading">
                    <h3>History</h3>
                    <h6><Link to={'/history'}>View all</Link></h6>

                </div>

                {loading ? 'Loading...' : TransactionData.length > 0 ? TransactionData.map((item, index) => (

                    (item?.mission_id || item?.reward_type == 2 || item?.reward_type == 3) && (
                        <div className="history__card" key={index}>
                            <div className="history__pic">
                                {item?.mission_id && <img src={item?.mission_id?.icon ? item?.mission_id?.icon : NA} alt="icon" />}
                                {item?.reward_type === 2 && <img src={DailyStreak} alt="icon" />}
                                {item?.reward_type === 3 && <img src={SpinIcon} alt="icon" />}
                            </div>
                            <div className="history__details">
                                <div className="top">
                                {item?.mission_id && <div className="name">{item?.mission_id?.title ? item?.mission_id?.title.substring(0, 18) : '---'}</div>}
                                        {item?.reward_type===2 && <div className="name">{'Daily Login'}</div>}
                                        {item?.reward_type===3 && <div className="name">{'Spinwheel'}</div>}
                                    <div className="logo">
                                        <div className="logo_pic">
                                            <img src={Points} width="18px" alt="logo" />
                                        </div>
                                        <div className="logo_name">
                                            <p style={{ fontSize: "16px" }}>{item?.reward_amt}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="middle">{item?.mission_id?.cat_id?.cat_name ? item?.mission_id?.cat_id?.cat_name : 'Daily Reward'}</div>

                                <div className="bottom">
                                    <div>
                                        Completed On:&nbsp;{moment.utc(item.created_at).format("Do MMM YY")}
                                    </div>
                                    <div>
                                        |
                                    </div>
                                    <div>
                                        {moment.utc(item.created_at).format('h:mm a')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : <div className='no__transaction'><h3>No transaction history available</h3></div>}


            </div>



        </>
    )
}
