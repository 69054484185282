import React,{useEffect} from 'react'
// import './successfull.css'
import success from '../../../assets/img/successful.png'
import unsuccess from '../../../assets/img/unsuccess.png'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom';



export default function Successfull() {
    const getSubmitSelector = useSelector(state => state.submit)
    const navigate = useNavigate()
    const { data: submitData, loading } = getSubmitSelector
    // window.onpopstate = function(event) {
    //     navigate('/home')
    //   };
    // useEffect(() => {
    //     window.addEventListener('popstate', () => {
           
    //         window.location.href = '/home';

    //       console.log('popstate event fired');
          
        
    //     });
    //   }, []);

    useEffect(()=>{
        window.location.hash = "no-back-button";
        window.location.hash = "Again-No-back-button";
        window.onhashchange = function () {
        window.location.hash = "no-back-button";
}
    },[])
    return (
        <>
        <div className="wrapper_success">
                <div className="container_success">
                    <div className="image_container_success">
                        <img src={success} width="400" height="400" alt="" />
                        <div className="text_container_success">

                        Mission Completed Successfully

                            

                        </div>
                    </div>
                    <button className="btn_success" onClick={()=>{navigate('/home')}}>Okay</button>
                </div>
            </div>

            <div className='wrapper_success_theme4'>
            <div className='first__success__section'>
                        <div className='image__section'>

                        </div>
            </div>
            <div className='second__success__section'>
                        <p>Quiz Completed Successfully</p>
                        <button onClick={()=>{navigate('/home')}}>Play more</button>
            </div>

           </div>
        
            
        </>
    )
}
