
import { Stack, Typography, } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { PropTypes } from "prop-types";
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import WestIcon from '@mui/icons-material/West';
// import HistoryIcon from '../assets/icon/history.png';
// import { getUserDetailsAction } from '../redux/user/getUserDetails';
import TimelineIcon from '@mui/icons-material/Timeline';
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import ArticleIcon from '@mui/icons-material/Article';
import HistoryIcon from '@mui/icons-material/History';
import PersonPinIcon from '@mui/icons-material/PersonPin';

export default function Appbar({ title }) {
    // state
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { pathname } = useLocation();
    const [sidebar, setSidebar] = React.useState(false)


    const userDetialsSelector = useSelector(state => state.getUserDetails)
    const { data } = userDetialsSelector



    // fn


    // React.useEffect(()=>{
    //     dispatch(getUserDetailsAction())
    // },[])

    const handleLogout = () => {
        // localStorage.removeItem('pwa_user_id')
        // localStorage.removeItem('pwa_publisher_id')
        localStorage.removeItem('pwa_token')
        localStorage.removeItem('pwa_app_theme')
        localStorage.removeItem('pwa_app_id')
        window.location.reload();

    }
    const backHistory = () => {
        if (pathname.includes('/home')) {
            // Redirect to a new URL
            window.location.href = localStorage.getItem('referringURL');

        } else {
            navigate(-1)
        }
    }

    // console.log("pathname==>",pathname)

    return (
        <>
            <AppBar className="app__bar" position="sticky" sx={{ height: 70, bgcolor: '#fff', display: 'flex', justifyContent: 'center'}}>
                <Stack direction="row" sx={{ margin: "0px 20px", display: "flex", justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', gap: "5px" }}>
                        <div className="typo" style={{ marginTop: "4px", color: "#000" }} width="15px" height="15px" /*onClick={() => { navigate(-1) }}*/ onClick={backHistory}><WestIcon /></div>
                        <Typography className="typo" sx={{ fontFamily: "'Gantari', sans-serif", fontStyle: "normal", fontSize: "20px", color: "#2C2C2C" }}>{title || "Quizwall"}</Typography>
                    </div>

                    <MenuIcon  onClick={() => { setSidebar(!sidebar) }} color='action'  className="menu"/>
                    {/* <Button onClick={handleLogout} variant="outlined">logout</Button> */}
                </Stack>
                
                <div>
                    {sidebar && <div className="sidebar_left" onClick={() => { setSidebar(!sidebar) }}>
                        
                    </div>}
                    {sidebar && <div className="sidebar">
                        <div className='head'>
                            <ClearIcon className='clearbtn' onClick={() => { setSidebar(!sidebar) }}  style={{float: "right", margin: "20px 20px 0px 0px" }} />
                        </div>
                        <div className='body' style={{ clear: "both" }}>
                            {/* <div> */}
                                <h2 style={{position:"absolute",top:"15px"}}>Menu</h2>
                                <hr className="divider" style={{marginTop:"65px"}}/>
                                <ul style={{marginTop:"35px"}}>
                                <Link style={{textDecoration:"none",color:"inherit"}} to={'/profile'}> <tr style={{marginTop:"10px"}}><li><PersonPinIcon fontSize='large'/></li><li><h3 style={{marginTop:"8px"}}>Profile</h3></li></tr></Link>
                                <Link style={{textDecoration:"none",color:"inherit"}} to={'/history'}> <tr style={{marginTop:"10px"}}><li><HistoryIcon fontSize='large'/></li><li><h3 style={{marginTop:"8px"}}>History</h3></li></tr></Link>
                                   <a style={{textDecoration:"none",color:"inherit"}} href='https://publisher.quizwall.io/privacy'> <tr style={{marginTop:"10px"}}><li><PrivacyTipIcon fontSize='large'/></li><li><h3 style={{marginTop:"8px"}}>Privacy Policy</h3></li></tr></a>
                                   <a style={{textDecoration:"none",color:"inherit"}} href="https://publisher.quizwall.io/term-of-use"> <tr style={{marginTop:"10px"}}><li><ArticleIcon fontSize='large'/></li><li><h3 style={{marginTop:"8px"}}>Terms and condition</h3></li></tr></a>
                                   

                                    
                                </ul>
                            {/* </div> */}
                        </div>

                    </div>}
                </div>
               
            </AppBar >
           

        </>
    )
}


Appbar.propTypes = {
    title: PropTypes.string,
};