import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import ExitPopup2 from './ExitPopup2';
import LayoutComp from './LayoutComp'
import { getQnaAction } from '../../../redux/screen/getqna'
import CryptoJS from 'crypto-js';

function Qna() {

    const user_id = JSON.parse(localStorage.getItem("pwa_user_id"));
    const search = useLocation().search;
    const mission_id = new URLSearchParams(search).get('missionId');
    const time_criteria = new URLSearchParams(search).get('time_criteria');



    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [count, setCount] = useState(3);
    const [updateCount, setUpdateCount] = useState(3);
    const [data, setData] = useState([])
    const [timeScreen, setTimeScreen] = useState(true);
    const [submitAnswer, setSubmitAnswer] = useState([])
    const [correctAnswer, setCorrectAnswer] = useState(0)
    const [showPopup, setShowPopup] = useState(false);
    const [timers, setTimers] = useState(time_criteria * 60 + 3)


    const [warning, setWarning] = useState(true)



    const getQna = useSelector(state => state.getQna)
    const { data: qnaData, loading: qnaLoading } = getQna


    const [timeTaken, setTimeTaken] = useState(0);


    // useEffect(() => {

    //     try {
    //         const secretKey = process.env.REACT_APP_MY_SECRET_KEY
    //         const decryptedBytes = CryptoJS.AES.decrypt(qnaData, secretKey);

    //         const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
    //         const jsonData = JSON.parse(decryptedData);
    //         console.log("jsonData-->",jsonData);
    //         setData(jsonData?.link_qns)

    //         // Proceed with further processing of the JSON data
    //     } catch (error) {
    //         console.error('Error parsing JSON:', error);
    //         // Handle the error gracefully, e.g., display a friendly message to the user
    //     }


    // }, [qnaData])

    useEffect(() => {
        if (!timeScreen) {
            const interval = setInterval(() => {
                setTimeTaken(prevSeconds => prevSeconds + 1);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [timeScreen]);


    useEffect(() => {
        if (time_criteria != 0) {
            const interval = setInterval(() => {
                setTimers(prevSeconds => prevSeconds - 1);
                if (timers == 0) {
                    setShowPopup(!showPopup)
                }
            }, 1000);


            return () => clearInterval(interval);
        }
    }, [timers]);







    useEffect(() => {
        window.location.hash = "no-back-button";
        window.location.hash = "Again-No-back-button";
        window.onhashchange = function () {
            window.location.hash = "no-back-button";
        }
    }, [])








    const next = () => {
        setCount(count + 1);

    }



    useEffect(() => {
        dispatch(getQnaAction({
            "mission_id": mission_id,

        })).then((res) => {
            const response=res.payload.data
            const secretKey = process.env.REACT_APP_MY_SECRET_KEY
            const decryptedBytes = CryptoJS.AES.decrypt(response, secretKey);

            const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
            const jsonData = JSON.parse(decryptedData);
            // console.log("jsonData-->",jsonData);
            setData(jsonData?.link_qns)
            // console.log("response-->",response)
        }).catch((err) => {
            
        });
    }, [dispatch])


    useEffect(() => {
        function updateProgressBar() {

            const elementWithColors = document.querySelector('.circular-progress');
            const computedStyle = getComputedStyle(elementWithColors);

            const primaryColor = computedStyle.getPropertyValue('--primary-color');
            const secondaryColor = computedStyle.getPropertyValue('--secondary-color');

            let progressBar = document.querySelector(".circular-progress");
            let valueContainer = document.querySelector(".value-container");

            let progressValue = 90;
            let progressEndValue = 0;
            let speed = 10;

            let progress = setInterval(() => {
                progressValue--;
                valueContainer.textContent = count >= 1 ? count : "Go!";
                progressBar.style.background = `conic-gradient(
              ${primaryColor} ${progressValue * 3.6}deg,
              ${secondaryColor} ${progressValue * 3.6}deg
            )`;
                if (progressValue == progressEndValue) {
                    if (updateCount >= 1) {

                        setUpdateCount(updateCount - 1)
                        setCount(count - 1)
                    } else {
                        setTimeScreen(false)
                    }
                    clearInterval(progress);
                    return;
                }
            }, speed);
        }


        updateProgressBar();
    }, [updateCount]);


    //    }

    return (
        <>



            {
                timeScreen ? (
                    <div className='main' style={{ backgroundColor: "#fff" }}>
                        <div className="container">
                            <div className="circular-progress">
                                <div className="value-container"></div>
                            </div>
                        </div>
                    </div>

                )
                    : (

                        <LayoutComp
                            key={data[count]?._id}
                            {...data[count]}
                            indexValue={count}
                            length={data?.length}
                            next={next}
                            answer_type={data[count]?.answer_type}
                            ans_options={data[count]?.options}
                            file_url={data[count]?.file_url}
                            type={data[count]?.type}
                            submitAnswer={submitAnswer}
                            setSubmitAnswer={setSubmitAnswer}
                            mission_id={mission_id}
                            correctAnswer={correctAnswer}
                            setCorrectAnswer={setCorrectAnswer}
                            timeTaken={timeTaken}
                            data={data[count]}
                            timer={time_criteria != 0 && timers}
                        />

                    )
            }


            {showPopup && <ExitPopup2 state={showPopup} setState={setShowPopup} />}
        </>

    )
}

export default Qna