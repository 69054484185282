import React, { useEffect } from 'react'
// import './successfull.css'
import success from '../../../assets/img/successful.png'
import unsuccess from '../../../assets/img/unsuccess.png'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import unsuccess2 from '../../../assets/img/unsuccess.png'



export default function Unuccessfull() {
    const navigate = useNavigate()
    const getSubmitSelector = useSelector(state => state.submit)
    const { data: submitData, loading } = getSubmitSelector
    // window.onpopstate = function(event) {
    //     navigate('/home')
    //   };

    // useEffect(() => {
    //     window.addEventListener('popstate', () => {
    //         // console.log(navigate)
    //         // window.history.forward();
    //         // navigate('/mission-qna?show=true',{state:{mission_id:mission_id}});
    //         // navigate('/home')
    //         window.location.href = '/home';

    //       console.log('popstate event fired');


    //     });
    //   }, []);
    useEffect(() => {
        window.location.hash = "no-back-button";
        window.location.hash = "Again-No-back-button";
        window.onhashchange = function () {
            window.location.hash = "no-back-button";
        }
    }, [])
    return (
        <>
            <div className="wrapper_success">
                <div className="container_success">
                    <div className="image_container_success">
                        <img src={unsuccess} width="400" height="400" alt="" />
                        <div className="text_container_success">

                            Mission Not Completed! Please <br /> Try Again



                        </div>
                    </div>
                    <button className="btn_success" onClick={() => { navigate('/home') }}>Okay</button>
                </div>
            </div>
            <div className='wrapper_unsuccess_theme4'>
                <div className='overlay'>
                    <div className='first__unsuccess__section'>
                        <div className='image__section'>
                            {/* <img src={unsuccess2}/> */}
                        </div>
                    </div>
                    <div className='second__unsuccess__section'>
                        <p>Mission Failed</p>
                        <button onClick={() => { navigate('/home') }}>Play more</button>
                    </div>
                </div>
            </div>


        </>
    )
}
