import React,{useEffect} from 'react'
import Style from './unsuccessfull.module.scss'
import Logo from '../../../../assets/theme6/quizwall.png'
import { useNavigate,useLocation } from 'react-router-dom'
import UnSuccessImg from '../../../../assets/theme6/successful.svg'






const Unsuccessfull = () => {
  const navigate = useNavigate()
  const search =useLocation().search
  const mission_id = new URLSearchParams(search).get('mission_id');

  // console.log(window.location.href)

  sessionStorage.setItem("lastScreen", window.location.href);


    useEffect(() => {
        
            window.location.hash = window.location.hash+"no-back-button";
            window.location.hash = "Again-No-back-button";
            window.onhashchange = function () {
            window.location.hash = "no-back-button";
            
        }

    }, [])

  window.scrollTo({
    top: 0,
    behavior: 'smooth' // Optional: smooth scrolling animation
});

  return (
    <>
      <div className={Style.container}>

        <div className={Style.logo}>
          <img src={Logo} width={"130px"} alt="logo" />
        </div>
        <div>

          <img className={Style.img} src={UnSuccessImg} alt="unsuccess" />
          <div className={Style.try__div}>
          <button className={Style.btn} onClick={() => {  navigate('/mission-detail', { state: { mission_id: mission_id } }) }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
            <path d="M14 5.83832L5.17647 1.60303M12.2353 10.7795L2 10.7795M14 15.3677L8 18.8971" stroke="white" stroke-width="2.11765" stroke-linecap="round" />
          </svg><p>Try Again</p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
              <path d="M2 5.83832L10.8235 1.60303M3.76471 10.7795L14 10.7795M2 15.3677L8 18.8971" stroke="white" stroke-width="2.11765" stroke-linecap="round" />
            </svg></button>
        </div>

        </div>
        

        <div style={{marginBottom:"100px"}}>
          <button className={Style.btn__home} onClick={() => { navigate('/home') }}><p>Back Home</p><svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0 0 21 19" fill="none">
            <path d="M0.874814 10.2915H3.49981V16.6248C3.49981 17.2547 3.77638 17.8588 4.26866 18.3042C4.76094 18.7496 5.42862 18.9998 6.12481 18.9998H7.87481C8.10688 18.9998 8.32944 18.9164 8.49353 18.768C8.65763 18.6195 8.74981 18.4181 8.74981 18.2082V14.7779C8.74981 14.3579 8.93419 13.9552 9.26238 13.6583C9.59057 13.3614 10.0357 13.1945 10.4998 13.1945C10.9639 13.1945 11.4091 13.3614 11.7373 13.6583C12.0654 13.9552 12.2498 14.3579 12.2498 14.7779V18.2082C12.2498 18.4181 12.342 18.6195 12.5061 18.768C12.6702 18.9164 12.8927 18.9998 13.1248 18.9998H14.8748C15.571 18.9998 16.2387 18.7496 16.731 18.3042C17.2233 17.8588 17.4998 17.2547 17.4998 16.6248V10.2915H20.1248C20.2978 10.2915 20.467 10.245 20.6108 10.158C20.7547 10.071 20.8668 9.9474 20.933 9.80276C20.9992 9.65813 21.0166 9.49898 20.9828 9.34543C20.9491 9.19188 20.8658 9.05084 20.7434 8.94012L11.1184 0.231789C10.9544 0.0833747 10.7318 0 10.4998 0C10.2678 0 10.0453 0.0833747 9.88119 0.231789L0.256189 8.94012C0.133855 9.05084 0.0505497 9.19188 0.0168031 9.34543C-0.0169436 9.49898 0.000384043 9.65813 0.0665953 9.80276C0.132807 9.9474 0.244929 10.071 0.388787 10.158C0.532646 10.245 0.701782 10.2915 0.874814 10.2915Z" fill="#FA5743" />
          </svg>
          </button>
        </div>
      </div>
    </>
  )
}

export default Unsuccessfull