import React, { useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getMissionAction } from '../../../../redux/screen/mission';
import { useNavigate } from 'react-router-dom'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import LimitPopup from './LimitPopup';
import LimitPopup2 from './LimitPopup2';
import Styles from '../style/trending.module.scss'
import Play_btn from '../../../../assets/theme6/btn_image.svg'
import Fav from '../../../../assets/theme6/fav1.svg'
import Unfav from '../../../../assets/theme6/unfav.svg'
// import authAxios from '../../../../config/authAxios';
import { favoriteAction } from '../../../../redux/profile/favorite';
import { unfavoriteAction } from '../../../../redux/profile/unfavorite';
import { getUserDetailsAction } from '../../../../redux/user/getUserDetails';
import { getMissionDetailAction } from '../../../../redux/screen/missiondetails';




function Trending() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    var limit = 10;
    const [page, setPage] = useState(0)
    const getMissionSelector = useSelector(state => state.getMission)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [isMore, setIsMore] = useState(false)
    const { length } = getMissionSelector
    const getAppSelector = useSelector(state => state.getApp)
    const { data: { is_ads_enabled }, Config: { daily_user_attempt_limit } } = getAppSelector
    const userDetialsSelector = useSelector(state => state.getUserDetails)
    const { data: userData } = userDetialsSelector
    const { data: { daily_attempt_limit_count } } = userDetialsSelector
    const [showPopup, setShowPopup] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [isFav, setIsFav] = React.useState([])
    const [userScreenWidth,setUserScreenWidth]=useState(null)
    const missionDetail = useSelector(state => state.getMissionDetail)
    const { data: missionDetailData, loading: missionDetailLoading, status, message } = missionDetail
  



    

    useEffect(() => {
        fetchUserDetails()
        setUserScreenWidth(window.innerWidth)

    }, [])

    const fetchUserDetails = async () => {

        const res = await dispatch(getUserDetailsAction())
        // console.log("res-->",res.payload.data.favorites)
        const data = res?.payload?.data?.favorites
        setIsFav([...data])


    }






    //   useEffect(()=>{
    // console.log("page-->",page);
    //   },[page])



    useEffect(() => {
       

        fetchData();
        // window.scrollTo(0, 0);

    }, [dispatch])

    const fetchData = async () => {
        try {
            setIsMore(false)
            setLoading(true)
            setOpen(true)
        // setPage(prev => prev + 1)

            let res = await dispatch(getMissionAction({ page: page, limit: limit }));
            if (res?.payload?.status === 200) {
                setData(prevData => [...prevData, ...res?.payload?.data]);
                // if(res?.payload?.length<limit){

                // }
        setPage(prev => prev + 1)

                if (res?.payload?.data.length >0) {
                    if(res?.payload?.data.length <10){
                        setIsMore(false)
                    }else{
                        setIsMore(true)

                    }
                }else{

                    setIsMore(false)
                }
                setLoading(false)
                setOpen(false)
        // window.scrollTo(0, window.innerHeight-50*limit*page);


            }

        } catch (error) {
            console.log("Error:", error);
        }
    };

    const clickHandler = (item) => {
        // console.log("daily_attempt_limit_count-->",daily_attempt_limit_count)
        // console.log("daily_user_attempt_limit-->",daily_user_attempt_limit)
        if (daily_attempt_limit_count >= daily_user_attempt_limit) {
            setShowPopup(true)
        } else {

            dispatch(getMissionDetailAction(item._id))
  .then((res) => {

    // console.log(res,"res")
    if(res.payload.status!==403)
            navigate('/mission-detail', { state: { mission_id: item._id } })
     else
      setOpen1(true)
    
    
    
   
  })
  .catch((error) => {
    // Handle any errors that occur during the dispatch
    console.error('Error dispatching mission detail action:', error);
  });

            

        }
    }

    // const viewMore = async () => {
    //     setIsMore(true)
    //     setLoading(true)
    //     setOpen(true)
    //     setPage(prev => prev + 1)
    //     let response = await dispatch(getMissionAction({ page: page, limit: 10 }))
    //     if (response?.payload?.status === 200) {
    //         // setData([...response?.payload?.data]);
    //         setData(prevData => [...prevData, ...response?.payload?.data]);
    //         if (response?.payload?.data.length > 0) {
    //             setIsMore(true)
    //         }else{
                
                
    //             setIsMore(false)
    //         }
    //         setLoading(false)
    //         setOpen(false)
    //     }



    // }

    function getTitle(email){

       
       
        try{
            // var div = document.getElementById('title_div');
            // var width = div.offsetWidth;
            // console.log("width",width);

        // console.log(userScreenWidth, "userScreenWidth");
        let deviceHeight=window.innerHeight
       
        if(email?.length<=20 ){
            
            return email
        }else{
              
                var fontSize=8;

                if(deviceHeight==768&&userScreenWidth==393){
                    fontSize=26
                }

                let remainScreenSize=userScreenWidth-320;
                let charCanAdjust=Math.floor(remainScreenSize/fontSize);
                if(userScreenWidth<=360){
                    if(email.length<email.substring(0,12)+charCanAdjust){
                        return email.substring(0,12+charCanAdjust)
                    }
                    return email.substring(0,12+charCanAdjust-3)+'...'
                }else{
                    if(email.length<email.substring(0,20)+charCanAdjust){
                        return email.substring(0,20+charCanAdjust)
                    }
                    return email.substring(0,20+charCanAdjust-3)+'...'
                }
               
        }
       
    }catch(error){
        console.log(error,"error")
    }

        
    }

    const favHandler = (value) => {
        // console.log("value", value)

        if (isFav.includes(value)) {
            setIsFav([...isFav.filter(item => item !== value)])
            dispatch(unfavoriteAction({ mission_id: value }))

        } else {
            setIsFav([...isFav, value])
            dispatch(favoriteAction({ mission_id: value }))
        }

    }




    return (
        <>

            <div className={Styles.trending__heading}>
                <p>All Quizzes</p>
            </div>
            <div className={Styles.mission_sec}>

                {loading ? <Box sx={{ marginTop:"10px",width: "95%" }}>

                    <Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"100%"} height={130} />
                    <br />
                    <Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"100%"} height={130} />
                    <br />
                    <Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"100%"} height={130} />
                    <br />
                    <Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"100%"} height={130} />


                </Box> : data?.length > 0 ? (data.map((item, index) => {

                    return (
                        // <div key={index}>
                        <>
                            <div className={Styles.continer} key={item?._id} >
                                <div key={"fav" + item?._id} onClick={() => { favHandler(item?._id) }} style={{ position: "absolute", top: "0px", right: "0px" }}>
                                    {isFav.includes(item?._id) ? <img src={Fav} alt="fav" /> : <img src={Unfav} alt="fav2" />}

                                </div>
                                <div className={Styles.container1} key={item?._id}>
                                    <div className={Styles.image_div}>
                                        <img src={item?.icon} alt="icon" />
                                    </div>
                                    <div className={Styles.item_container}  >
                                        {/* <div className={Styles.Cash_bonanza_div}>{(userScreenWidth<380 && item?.title.length>14) ? item?.title.substring(0,14)+'...':item?.title.substring(0,18)}</div> */}
                                        <div className={Styles.Cash_bonanza_div}>{getTitle(item?.title)}</div>
                                        <div className={Styles.Fast_food_div}>{item?.sub_category}</div>
                                        <div className={Styles.fast_food_detail_container}>
                                            <div className={Styles.food_detils} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><SmartDisplayOutlinedIcon style={{ color: "#b6b6b6", fontSize: "16px" }} /></div>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;&nbsp;{item?.no_of_question}</div>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;Q</div>
                                            </div>
                                            |
                                            <div className={Styles.food_detils} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><HourglassEmptyIcon style={{ color: "#b6b6b6", fontSize: "16px" }} /></div>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;&nbsp;{item?.time_criteria}</div>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;mins</div>
                                            </div>
                                            |
                                            <div className={Styles.food_detils} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><PersonOutlineOutlinedIcon style={{ color: "#b6b6b6", fontSize: "18px" }} /></div>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;&nbsp;{item.participants}</div>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button onClick={() => clickHandler(item)} className={Styles.button_9}>
                                    Play Now
                                    <img className={Styles.btn_img} src={Play_btn} width={"60px"} height={"60px"} />
                                </button>

                            </div>

                            {index !== item.length - 1 && is_ads_enabled && <div className={Styles.continer}>
                                ads
                            </div>}

                        </>

                        // </div>
                    )
                })) : (<Box sx={{ width: "95%" }}>

                    <Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"100%"} height={130} />
                    <br />
                    <Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"100%"} height={130} />
                    <br />
                    <Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"100%"} height={130} />
                    <br />
                    <Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"100%"} height={130} />


                </Box>)}

                {isMore &&
                    <p  className={Styles.view_more} onClick={() => fetchData()}>View More</p>
                }

                {showPopup && <LimitPopup open={showPopup} setOpen={setShowPopup} />}
                {open1 &&(<LimitPopup2 open={open1} setOpen={setOpen1} />)}


            </div>

        </>
    )
}

export default Trending