import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../config/authAxios"


const initialState = { status: null, message: "", loading: false, data: [], error: "",Config:{}}

export const appAction = createAsyncThunk(
    'app',
    async (data, { rejectWithValue }) => {
        try {

            const res = await axios.get(`/user/api/app`,)
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(appAction.pending, (state) => {
                state.data = []
                state.loading = true
            })
            builder.addCase(appAction.fulfilled, (state, action) => {
                state.data = false
                state.loading = false
                state.message = action.payload.message
                state.status = action.payload.status
                state.Config=action.payload.Config
            })
            builder.addCase(appAction.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload.error
                state.message = action.payload.message
                state.status = action.payload.status
                state.Config=action.payload.Config

            })
        },

})



export const appReducer = appSlice.reducer