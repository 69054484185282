import { Stack, Typography, } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { PropTypes } from "prop-types";
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import WestIcon from '@mui/icons-material/West';
import HistoryIcon from '../../../../assets/icon/history.png';
import { getUserDetailsAction } from '../../../../redux/user/getUserDetails';
import TimelineIcon from '@mui/icons-material/Timeline';
import Quizwall from '../../../../assets/theme6/quizwall.png'

import Arrow from '../../../../assets/theme6/arrow_back.png'
import Fav from '../../../../assets/theme6/fav.png'
import Style from '../style/appbar.module.scss'

const Appbar = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { pathname } = useLocation();


    const userDetialsSelector = useSelector(state => state.getUserDetails)
    const { data } = userDetialsSelector



    // fn


    // React.useEffect(() => {
    //     dispatch(getUserDetailsAction())
    // }, [])

    const handleLogout = () => {
        // localStorage.removeItem('pwa_user_id')
        // localStorage.removeItem('pwa_publisher_id')
        localStorage.removeItem('pwa_token')
        localStorage.removeItem('pwa_app_theme')
        localStorage.removeItem('pwa_app_id')
        window.location.reload();

    }
    const backHistory = () => {
        if (pathname.includes('/home')) {
            // Redirect to a new URL
            window.location.href = localStorage.getItem('referringURL');

        } else {
            navigate(-1)
        }
    }
    return (
        <>
            <div className={Style.container}>


                <div>
                    <Stack direction="row" sx={{position:"relative", margin: "0px 20px", display: "flex", justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', gap: "5px", }}>
                            <div className="typo" style={{ marginTop: "24px", color: "#000",zIndex:2}} width="25px" height="15px" onClick={backHistory}><img src={Arrow}/></div>
                            <div className="typo" style={{ marginTop: "24px", color: "#000",zIndex:2 }} width="25px" height="15px" onClick={backHistory}><img width={"150px"} src={Quizwall}/></div>
                            {/* <Typography className="typo" sx={{ fontFamily: "'Gantari', sans-serif", fontStyle: "normal", fontSize: "20px", color: "#2C2C2C" }}>{"Quizwall"}</Typography> */}
                        </div>
                       {/* { pathname.includes('/favorite')===false &&  <div style={{display:'flex',alignItems:"center",justifyContent:"center",marginTop:"12px",zIndex:"10"}} onClick={()=>{navigate('/favorite')}}>
                           <img src={Fav} width={"40px"}/>
                        </div>} */}


                    </Stack>
                </div>

            </div>
        </>
    )
}


export default Appbar