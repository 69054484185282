import React, { useEffect, useState } from 'react';
import {Route, Routes,useNavigate} from "react-router-dom";
import Account from '../screen/account/Account';
import Login from '../screen/auth/Login';
import NotFound from "../screen/NotFound";
import QuestionAnswer from '../screen/question-answer/QuestionAnswer';
import Home from '../screen/user/home/Home';
import Subcategory from '../screen/user/subcategory/subcategory';
import Mission from '../screen/user/mission/mission'
import MissionDetails from '../screen/user/mission-details/missiondetails'
import Qna from '../screen/user/qna/qna'
import Successfull from '../screen/user/last/successfull';
import Unsuccessfull from '../screen/user/last/unsuccessfull'
import ProtectedRoute from './ProtectedRoute';
import Profile from '../screen/profile/Profile';
import History from '../screen/profile/History'
import Maintinance from '../screen/maintinance'
import DailyLogin from '../screen/dailylogin'
import SpinWheel from '../screen/user/home/Spinwheel';
// import for theme 6
import Home6 from '../screen/user/theme6'
import Subcategory6 from '../screen/user/theme6/Subcategory/Subcategory'
import Mission6 from '../screen/user/theme6/mission/Mission'
import Mission_details6 from '../screen/user/theme6/Mission-details/Mission_details';
import Profile6 from '../screen/user/theme6/Profile/profile'
import History6 from '../screen/user/theme6/Profile/History'
import Favorite from '../screen/user/theme6/Profile/Favorite';
import Qna6 from '../screen/user/theme6/Qna/Start'
import Successfull6 from '../screen/user/theme6/last/Successfull';
import Unsuccessfull6 from '../screen/user/theme6/last/Unsuccessfull';
import { appAction } from '../redux/app/app';
import { useDispatch } from "react-redux";










export default function Index() {
  const [reload, setReload] = useState(true);
  const isAuthenticatedAppId = JSON.parse(localStorage.getItem("pwa_app_id"));
  const [maintinanceMode, setMaintinanceMode] = useState(false)
  const navigate=useNavigate()

  const dispatch = useDispatch()




  useEffect(() => {
    const fetchData = async () => {
      try {
        // if (isAuthenticatedAppId!=null||isAuthenticatedAppId!=''||isAuthenticatedAppId!=undefined) {
          var app = await dispatch(appAction({ appId: isAuthenticatedAppId }));
          if (app?.payload?.status == 200) {
            localStorage.setItem("is_maintinance_mode", JSON.stringify(app?.payload?.Config.is_maintinance_mode));
            setMaintinanceMode(app?.payload?.Config.is_maintinance_mode)
            if (JSON.parse(localStorage.getItem("pwa_app_theme")) != app?.payload?.Config?.quizwall_io_theme) {
              localStorage.setItem("pwa_app_theme", JSON.stringify(app?.payload?.Config?.quizwall_io_theme));
              window.location.reload();
              // navigate('/home')
            }
          }
        // }
      } catch (error) {
        console.log("Error:", error);
      }


    };

    fetchData();

  }, [dispatch,navigate])



const theme=localStorage.getItem('pwa_app_theme')

console.log("theme-->",theme)





  return (
    // <BrowserRouter>
      <Routes>

        
        <Route path={"/home"} element={<ProtectedRoute>{JSON.parse(theme)==6?<Home6/>:<Home/>}</ProtectedRoute>} />
        <Route path="/account" element={<Account />} />
        <Route path="/favorite" element={<Favorite />} />
        <Route path="/qns-ans" element={<QuestionAnswer />} />
        {/* <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} /> */}
        <Route path="/sub-category" element={JSON.parse(theme)==6?<Subcategory6/>:<Subcategory />} />
        <Route path="/missions" element={JSON.parse(theme)==6?<Mission6/>:<Mission />} />
        <Route path="/mission-detail" element={JSON.parse(theme)==6?<Mission_details6/>:<MissionDetails />} />
        <Route path="/mission-qna" element={JSON.parse(theme)==6?<Qna6/>:<Qna/>} />
        <Route path="/mission-success" element={JSON.parse(theme)==6?<Successfull6/>:<Successfull />}/>
        <Route path="/mission-unsuccess" element={JSON.parse(theme)==6?<Unsuccessfull6/>:<Unsuccessfull />}/>
        <Route path="/profile" element={JSON.parse(theme)==6?<Profile6/>:<Profile/>}/>
        <Route path="/history" element={JSON.parse(theme)==6?<History6/>:<History />} />
        {/* <Route path="/dailylogin" element={<ProtectedRoute><DailyLogin /></ProtectedRoute>} /> */}
        {/* <Route path="/spinwheel" element={<ProtectedRoute><SpinWheel /></ProtectedRoute>} /> */}
        <Route path="/maintinance" element={JSON.parse(localStorage.getItem('is_maintinance_mode'))
 ? <Maintinance /> : <navigate to="/home" />} />
        <Route path="/" element={<Login />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="*" element={<ProtectedRoute>{theme==6?<Home6/>:<Home/>}</ProtectedRoute>} />
      </Routes>
    // </BrowserRouter> 
  )
}


