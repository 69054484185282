import React, { useEffect, useState,useRef } from 'react'
import { Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom'
import Appbar from '../../../comman/Appbar'
import { useDispatch, useSelector } from 'react-redux'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { getBannerAction } from '../../../redux/screen/banner';
import Category from './category';
import Trending from './trending';
import LoadingUI from '../../../comman/LoadingUI';
import { Link } from "react-router-dom";
import Heart from '../../../assets/icon/favorite_border.png'
import RocketIcon from '@mui/icons-material/Rocket';
import StreakPopup from './StreakPopup'
import Spin from '../../../assets/img/spinwheel_small.png'
// import { getUserDetailsAction } from '../../../redux/user/getUserDetails';


import moment from 'moment';


export default function Home() {
    const userDetialsSelector = useSelector(state => state.getUserDetails)
    const { data } = userDetialsSelector

    // console.log("momment-->",moment().format());
    // console.log("data-->",data);

    
   
    // state
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const banner = useSelector(state => state.getBanner)
    const { data: bannerData, loading: bannerLoading } = banner
    const getMissionSelector = useSelector(state => state.getMission)
    const { data: missionData, loading } = getMissionSelector
    const [popup,setpopup]=useState(false)
    // console.log("data-->",data?.is_today_logged_in)

    // useEffect(()=>{
    //     console.log(data?.is_today_logged_in,"hello");
    //     if(data?.is_today_logged_in===false){
    //         setpopup(true)
    //     }
    // },[data?.is_today_logged_in])
    

    // let isReload = JSON.parse(localStorage.getItem("reload"));
     
    // if(isReload){
    //     window.location.reload()
    //     localStorage.setItem(
    //         "reload",
    //         JSON.stringify(false)
    //     );
    // }
     

   

    const carouselStyle = {
        padding: '0px 0px 15px 0px',
       
        background: "7px solid red"
    }
    let isScroll = JSON.parse(localStorage.getItem("pwa_app_scroll"));
   
    const scrollToTop = () => {
        window.scrollTo(0,100000);
      };

      useEffect(() => {
        setTimeout(()=>{
            if(isScroll==true){
                scrollToTop();
                localStorage.setItem(
                    "pwa_app_scroll",
                    JSON.stringify(false)
                );
            }
        },500)
      }, [])

    

    useEffect(() => {
        dispatch(getBannerAction())
    }, [dispatch])

    useEffect(()=>{
        window.location.hash = "no-back-button";
        window.location.hash = "Again-No-back-button";
        window.onhashchange = function () {
        window.location.hash = "no-back-button";
}
    },[])

    return (
        <>
            
            <div className="main_div">
                <Appbar title='QuizWall' />
                {/* <DrawerUI /> */}
                <div style={carouselStyle} >
                    { bannerLoading?<LoadingUI/>:
                    <Carousel showThumbs={false} autoPlay={true} interval={3000} infiniteLoop>

                        {bannerData.map((item, index) => (
                            <div key={index} onClick={() => window.location.href = item.link}>
                                <Link to={item.link}>
                                <img src={item.image} alt="img"/>
                                </Link>
                            </div>
                        ))}

    
                    </Carousel>
                    }
                </div>

                <div>
                    <Category />
                    {/* <div className='spinewheel__parent' onClick={()=>{navigate('/spinwheel')}}>
                    <div className="spinewheel">Spin & Earn</div>
                    <div className="spinewheel__image"><img src={Spin} width="50px"/></div>
                    </div> */}
                    {/* <p> Trending Quizzes</p> */}
                    <Trending/>
                </div>
                <div className="bottom_text">Pick, Play & <span className='color_text'>Earn</span></div>
                <div  id="bottomDiv" className="jingle_text">Made with <img style={{position:'relative',top:"5px"}} src={Heart}/> by QuizWall.IO</div>
                <div className="rocket_color" style={{paddingBottom:"10px",borderRadius:"50%",fontSize:"16px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}><RocketIcon style={{fontSize:"48px"}} onClick={()=>{window.scrollTo(0,0);}}/><Typography sx={{fontFamily:"gantari"}}>Take me upp</Typography></div>
            </div>
                
                {popup && <StreakPopup popup={popup} setpopup={setpopup}/>}
        </>
    )
}